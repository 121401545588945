import * as styles from './product-single-page.module.css';

import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Button,
	HStack,
	Image,
	Input,
	Link,
	NumberDecrementStepper,
	NumberIncrementStepper,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	SimpleGrid,
	Skeleton,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Text,
	VStack,
	useMediaQuery,
} from '@chakra-ui/react';
import { PageProps, graphql } from 'gatsby';
import React, { useState } from 'react';

import AddToCartInput from './AddToCartInput';
import { BsListCheck } from 'react-icons/bs';
import { GoStack } from 'react-icons/go';
import GridTable from './GridTable/GridTable';
import { Helmet } from 'react-helmet';
import { HorizontalProductCard } from './Product/HorisontalProductCard';
import { ImLab } from 'react-icons/im';
import ImageZoom from './Product/ImageZoom';
import Layout from './Base/Layout';
import OneProductBundle from './Product/OneProductBundle';
import { PiNotebook } from 'react-icons/pi';
import { ProductBreadcrumb } from './Shop/ProductBreadcrumbs';
import ProductBundles from './Product/ProductBundles';
import ProductsFAQ from './FAQ/productsFaq';
import { RelatedProductsCard } from './Product/RelatedProductCard';
import { RiFlag2Line } from 'react-icons/ri';
import { SEO } from './Base/Seo';
import { brandsToExclude } from '../queries/toExclude';
import { fetchAllProducts } from '../queries/fetchAllProducts';
import mediumZoom from 'medium-zoom';
import useAuth from './useAuth';

const Product: React.FC<ProductProps> = ({
	data,
	pageContext,
	location,
}: any) => {
	const {
		wpSimpleProduct: {
			name,
			databaseId,
			shortDescription,
			description,
			price,
			regularPrice,
			tierPrice,
			stockStatus,
		},
		wpSimpleProduct: { image },
		wpSimpleProduct: {
			galleryImages: { images },
		},
		wpSimpleProduct: {
			productCategories: { catName },
		},
		wpSimpleProduct: {
			productTags: { brandName },
		},
		wpSimpleProduct: {
			attributes: { attrName },
		},
		wpSimpleProduct: {
			related: { relatedProducts },
			upsell: { upsellProducts },
			crossSellProducts: { bundles },
		},
		wpSimpleProduct: {
			additionalProductInfo: { additionalProductInfoRepeater },
		},
	} = data;

	const tierPricesData = [
		{
			amount: '1 - 5',
			price: tierPrice.tierPrice?.[0]?.price,
		},
		{
			amount: '6 - 10',
			price: tierPrice.tierPrice?.[0]?.price2,
		},
		{
			amount: '11 - 20',
			price: tierPrice.tierPrice?.[0]?.price3,
		},
		{
			amount: '21+',
			price: tierPrice.tierPrice?.[0]?.price4,
		},
	];

	const [isOnSale] = useState(data.wpSimpleProduct.onSale);

	const categoryName = catName.map((item: any) => {
		return item.name;
	});
	const categorySlug = catName.map((item: any) => {
		return item.slug;
	});

	const { loggedIn, isUnlicensedUser, isCustomerDefault } = useAuth();

	const isRestricted = brandName.every((item: any) =>
		brandsToExclude.includes(item.name)
	);

	let session: string | null = null;
	if (typeof window !== 'undefined') {
		session = sessionStorage.getItem('shouldRedirect');
	}

	// Function for getting the image src
	const [currentImage, setCurrentImage] = useState(
		data.wpSimpleProduct.image?.sourceUrl
	);
	// const meta = data?.wpSimpleProduct.metaData[3];

	const getImageSrc = (e: any) => {
		setCurrentImage(e.target.src);
	};

	// zoom image
	const zoom = React.useRef(mediumZoom());

	let [qty, setQty] = useState(1);

	// if (loading) return <Loading />;
	const faqItems = data.wpSimpleProduct.faq.questionsrepeater;

	const faqSchema = {
		'@context': 'https://schema.org/',
		'@type': 'FAQPage',
		mainEntity: faqItems
			? faqItems.map(({ question, answer }: any) => ({
					'@type': 'Question',
					name: question,
					acceptedAnswer: {
						'@type': 'Answer',
						text: answer,
					},
			  }))
			: [],
	};

	const productSchema = {
		'@context': 'http://schema.org',
		'@type': 'Product',
		name: name,
		description: shortDescription,
		sku: databaseId,
		url: location.href,
		image: image.sourceUrl,
		offers: {
			'@type': 'Offer',
			price: price ? parseFloat(price.replace('$', '')) : undefined,
			priceCurrency: 'USD',
			availability: `https://schema.org/${stockStatus}`,
		},
		brand: {
			'@type': 'Brand',
			name: brandName[0]?.name || '',
		},
	};

	const combinedSchema =
		faqSchema.mainEntity.length > 0
			? [faqSchema, productSchema]
			: [productSchema];
	const combinedJsonLd = JSON.stringify(combinedSchema, null, 2);

	const combinedProducts = [...upsellProducts, ...relatedProducts];

	// Check if any product is restricted
	const hasRestrictedProducts = combinedProducts.some((item) =>
		brandsToExclude.includes(item.name)
	);

	const [isLargerScreen] = useMediaQuery('(max-width: 768px)');

	// Product subsitute logic
	const products = fetchAllProducts();

	const suggestedProducts = data.wpSimpleProduct.productSubstitute?.substitute;

	const filteredSuggestedProducts = products?.filter((item: any) => {
		const mentionedProduct = suggestedProducts
			?.slice(0, 4)
			.find((mp: any) => mp.productId == item.sku);

		if (mentionedProduct) {
			const isProductExcluded = item.productTags.brandName.every((i: any) =>
				brandsToExclude.includes(i.name)
			);

			if (
				(!loggedIn || isCustomerDefault || isUnlicensedUser) &&
				isProductExcluded
			) {
				return false;
			} else {
				return true;
			}
		}
		return false;
	});

	// Klaviyo view-product integration
	const trackViewedProduct = () => {
		//@ts-ignore
		if (typeof window !== 'undefined' && window.klaviyo) {
			var item = {
				ProductName: name,
				ProductID: databaseId,
				Categories: catName.map((category: any) => category.name),
				ImageURL: image?.sourceUrl,
				Brand: brandName,
				Price: price,
			};
			//@ts-ignore
			window.klaviyo.push(['track', 'Viewed Product', item]);
		} else {
			console.warn('Klaviyo is not initialized or loaded.');
		}
	};

	React.useEffect(() => {
		trackViewedProduct();
	}, []);

	const icons = [
		{ component: BsListCheck, name: 'List Check' },
		{ component: ImLab, name: 'Lab' },
		{ component: PiNotebook, name: 'Notebook' },
		{ component: RiFlag2Line, name: 'Flag' },
	];

	return (
		<Layout>
			<Helmet>
				<script type='application/ld+json'>{combinedJsonLd}</script>
			</Helmet>
			<Box
				// p={{ base: "140px 24px", md: "140px 70px", lg: "90px 120px" }}
				pt={{ base: '64px', md: '64px', lg: '48px' }}
				px={{ base: '24px', md: '70px', lg: '120px' }}
				pb='80px'
				bg='#FCFCFC'>
				<HStack
					justifyContent='flex-start'
					py='20px'>
					<ProductBreadcrumb
						shop='Shop'
						brand={pageContext.name}
					/>
				</HStack>
				<Link href='/promotions'>
					<Image
						src={
							isLargerScreen
								? '/shop-banner-mobile.webp'
								: '/dm-shop-banner.webp'
						}
						alt='DM Shop Banner'
						borderRadius={'8px'}
						mb={8}
					/>
				</Link>
				<div className={styles.productContainer}>
					<div className={styles.productWrapper}>
						<div className={styles.productImage}>
							{data.wpSimpleProduct.promotions?.onPromotion && (
								<Box
									position='absolute'
									zIndex='99'
									top='10px'
									left='8px'
									w='fit-content'
									bg='#F0FFF4'
									color='#39A26A'
									textTransform='uppercase'
									borderRadius='8px'
									px='8px'>
									Low Quantity
								</Box>
							)}
							{data.wpSimpleProduct.promotions?.additionalTag && (
								<Box
									position='absolute'
									zIndex='99'
									top='40px'
									left='8px'
									w='fit-content'
									bg='#FC8181'
									color='#fff'
									textTransform='uppercase'
									borderRadius='8px'
									px='8px'>
									{data.wpSimpleProduct.promotions?.additionalTag}
								</Box>
							)}
							<ImageZoom
								alt={data.wpSimpleProduct.image?.altText}
								src={currentImage}
								background='rgba(0, 0, 0, .6)'
								zoom={zoom.current}
							/>
						</div>
						{images && images.length > 0 ? (
							<div className={styles.sliderWrapper}>
								<HStack pt='4'>
									{images.slice(0, 3).map((image: any) => (
										<Image
											fallback={<Skeleton />}
											className={styles.slider}
											src={image.sourceUrl}
											margin='0'
											objectFit='contain'
											onClick={getImageSrc}
											cursor='pointer'
											boxShadow='0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)'
										/>
									))}
								</HStack>
							</div>
						) : (
							<></>
						)}
					</div>
					<div className={styles.porductdetails}>
						<div className={styles.details}>
							<Text
								as='h1'
								fontSize='1.88rem!important'
								className={styles.product}>
								<b className={styles.productname}>{name}</b>
							</Text>
							{(!loggedIn || isUnlicensedUser || isCustomerDefault) &&
							isRestricted ? (
								<div
									className={styles.productCardResButton}
									role='button'>
									<Link
										className='chakra-button css-1oloaee'
										href='/contact-us'>
										<Button
											variant='solid'
											colorScheme='#000'
											p={'12px'}
											ml='16px'
											color='#000'
											border='1px solid #120B0C'
											borderRadius='full'
											size='0.75rem'
											width='full'
											_hover={{ bg: '#120B0C', color: '#fff' }}>
											Contact Us
										</Button>
									</Link>
								</div>
							) : (
								<>
									<div className={styles.price}>
										{isOnSale ? (
											<>
												<Text
													color='#DF7C6C'
													fontSize='14px'
													style={{ textDecoration: 'line-through' }}>
													{regularPrice}
												</Text>
												<Text
													color='gray.800'
													fontSize='1.5rem'
													fontWeight='500'>
													{' '}
													{price}{' '}
													<span className={styles.pricespan}>/unit</span>
												</Text>
											</>
										) : (
											<Text
												color='gray.800'
												fontSize='1.5rem'
												fontWeight='500'>
												{' '}
												{price} <span className={styles.pricespan}>/unit</span>
											</Text>
										)}
									</div>
									<div className={styles.qtyaddtocart}>
										<div className={styles.qtyWrapper}>
											<div className={styles.qtylabel}>
												<div className={styles.qty1}>QTY</div>
											</div>
											<NumberInput
												min={1}
												defaultValue={1}
												width='50%'
												mx='2'
												focusBorderColor='#d28c49'
												onBlur={(e) => {
													setQty(parseInt(e.currentTarget.value));
												}}>
												<NumberInputField height='48px' />
												<NumberInputStepper>
													<NumberIncrementStepper />
													<NumberDecrementStepper />
												</NumberInputStepper>
											</NumberInput>
										</div>
										<div className={styles.label}>
											{!loggedIn ? (
												<div
													className={styles.productcardButton}
													role='button'>
													<Link
														className='chakra-button css-1oloaee'
														href='/sign-in'>
														<Button
															variant='solid'
															colorScheme='#000'
															color='#000'
															size='0.75rem'>
															SIGN IN TO ORDER
														</Button>
													</Link>
												</div>
											) : (
												<div>
													<AddToCartInput
														quantity={qty}
														productId={databaseId}
														name={name}
														price={price}
														isSuggested={false}
													/>
												</div>
											)}
										</div>
									</div>

									{/* PRODUCT BUNDLES */}

									{bundles && (
										<VStack
											w='100%'
											alignItems='flex-start'
											p='16px 16px 24px 16px'>
											<HStack alignItems='center'>
												<GoStack
													width='20px !important'
													height='20px'
													strokeWidth='1px'
												/>
												<Text
													color='gray.800'
													fontSize='16px'
													fontWeight='600'>
													Best bundled with
												</Text>
											</HStack>
											<VStack w='100%'>
												{bundles?.length == 1 ? (
													<>
														<OneProductBundle
															crossSellProducts={bundles}
															products={products}
															mainProductId={databaseId}
															mainProductPrice={price}
														/>
													</>
												) : (
													<>
														<ProductBundles
															crossSellProducts={bundles}
															products={products}
															mainProductId={databaseId}
															mainProductPrice={price}
															showProductImages={true}
														/>
													</>
												)}
											</VStack>
										</VStack>
									)}

									{/* TIER PRICE */}
									{data.wpSimpleProduct.promotions?.onPromotion ? (
										<Text pl='16px'>
											Promo and tiered pricing discounts cannot be combined
										</Text>
									) : (
										<GridTable data={tierPricesData} />
									)}
								</>
							)}

							<div className={styles.commodities}>
								<div className={styles.row1}>
									<div className={styles.feature}>
										<img
											className={styles.userCircleIcon}
											alt=''
											src='/usercircle.svg'
										/>
										<div className={styles.split} />
										<div className={styles.worldwideDelivery}>
											Free Shipping for Orders <br /> Above $500
										</div>
									</div>
									<div className={styles.feature}>
										<img
											className={styles.userCircleIcon}
											alt=''
											src='/headset.svg'
										/>
										<div className={styles.split} />
										<div className={styles.worldwideDelivery}>
											Dedicated Account Managers
										</div>
									</div>
								</div>
								<div className={styles.row2}>
									<div className={styles.feature}>
										<img
											className={styles.userCircleIcon}
											alt=''
											src='/clock.svg'
										/>
										<div className={styles.split} />
										<div className={styles.worldwideDelivery}>
											Shipping Within 48h
										</div>
									</div>
									<div className={styles.feature}>
										<img
											className={styles.userCircleIcon}
											alt=''
											src='/percentage-icon.png'
										/>
										<div className={styles.split} />
										<div className={styles.worldwideDelivery}>
											Wholesale Prices
										</div>
									</div>
								</div>
							</div>
							<div className={styles.branddetails}>
								{attrName?.map((item: any, index: number) => {
									const optionsString = item.options.toString();
									const sanitizedOptions = optionsString
										.replace(/[^a-zA-Z\s]/g, '')
										.replace(/\s+/g, '-')
										.replace(/%20/g, '-');
									return (
										<div
											className={styles.tag}
											key={`${index}${item.name}`}>
											<b className={styles.price3}>{item.name}</b>
											{item.name === 'Brand:' ? (
												<div className={styles.belotero}>
													{brandName?.map((brandItem: any) => {
														if (brandItem.name === optionsString) {
															return (
																<Link
																	href={`/shop/brand/${brandItem.slug}`}
																	key={brandItem.slug}>
																	{optionsString}
																</Link>
															);
														}
													})}
												</div>
											) : (
												<div className={styles.belotero}>{item.options}</div>
											)}
										</div>
									);
								})}
							</div>

							<div className={styles.commodities}>
								{' '}
								<Text dangerouslySetInnerHTML={{ __html: shortDescription }} />
							</div>
						</div>
					</div>
				</div>
			</Box>

			{/* ADDITIONAL INFO */}
			<Box
				id='additonalInfo'
				as='section'
				background='#FCFCFC'
				px={{ base: '40px', lg: '80px', xl: '120px' }}
				display={additionalProductInfoRepeater?.length > 0 ? 'block' : 'none'}>
				{!isLargerScreen ? (
					<>
						{' '}
						<Tabs
							isFitted
							variant='enclosed'>
							<TabList
								mb='1em'
								borderColor='#D7DDD3'>
								{additionalProductInfoRepeater?.map(
									(item: { name: string }) => {
										return (
											<Tab
												fontSize='14px'
												p='10px 16px'
												color='#737373'
												_selected={{
													fontWeight: '600',
													color: '#120B0C',
													border: '1px solid #D7DDD3',
													marginBottom: '-1px',
													borderTopLeftRadius: '12px',
													borderTopRightRadius: '12px',
													borderBottomColor: '#FCFCFC',
												}}>
												{item.name}
											</Tab>
										);
									}
								)}
							</TabList>
							<TabPanels>
								{additionalProductInfoRepeater?.map(
									(item: { description: string }) => {
										return (
											<TabPanel>
												<Text
													fontSize='14px'
													color='#595959'
													dangerouslySetInnerHTML={{
														__html: item.description,
													}}></Text>
											</TabPanel>
										);
									}
								)}
							</TabPanels>
						</Tabs>
					</>
				) : (
					<>
						<Accordion
							defaultIndex={0}
							allowToggle>
							{additionalProductInfoRepeater?.map(
								(item: { name: string; description: string }) => {
									return (
										<AccordionItem
											p='16px 4px'
											borderColor='#D7DDD3'>
											<h2>
												<AccordionButton
													color='#737373'
													_hover={{
														bg: 'transparent',
													}}
													_focus={{
														bg: 'transparent',
													}}
													_expanded={{
														color: 'gray.900',
														fontWeight: '600',
														bg: 'transparent',
													}}>
													<Box
														as='span'
														flex='1'
														textAlign='left'>
														{item.name}
													</Box>
													<AccordionIcon color='#D28C49' />
												</AccordionButton>
											</h2>
											<AccordionPanel pb={4}>
												<Text
													fontSize='14px'
													color='#595959'
													dangerouslySetInnerHTML={{
														__html: item.description,
													}}></Text>
											</AccordionPanel>
										</AccordionItem>
									);
								}
							)}
						</Accordion>
					</>
				)}
			</Box>

			<Box
				display={
					data.wpSimpleProduct.faq.questionsrepeater == null ? 'none' : 'block'
				}>
				<ProductsFAQ props={data.wpSimpleProduct.faq} />
			</Box>

			{/* SUGGESTED PRODUCTS SECTION */}
			{filteredSuggestedProducts.length > 0 ? (
				<Box
					id='suggestedProducts'
					as='section'>
					<Box
						bgColor='#FBF5F0'
						px={{ base: '20px', md: '120px' }}
						w='100%'
						h={{ base: '100%', md: '100%' }}>
						<HStack
							py='80px'
							flexDirection={{ base: 'column', lg: 'row' }}
							gap={{ base: '40px', lg: '0px', xl: '16px', '2xl': '0px' }}
							h='100%'
							justifyContent='space-between'>
							<VStack
								w={{ base: '100%', lg: '500px' }}
								alignItems='flex-start'
								gap='32px'>
								<Box
									h='2px'
									w='24px'
									bg='beige.500'></Box>
								<Box>
									<Text
										fontSize='36px'
										lineHeight='36px'
										fontWeight='400'
										pb='8px'>
										Alternative Products
									</Text>
									<Text fontSize='16px'>
										Check out these alternatives that might suit your needs
									</Text>
								</Box>
								<Link href={`/category/${categorySlug[0]}`}>
									<Button
										variant='outline'
										h='45px'
										padding='12px 20px'
										fontWeight='700'
										border='0.4px solid #120B0C'
										borderRadius='48px'
										fontSize='12px'
										_hover={{ color: '#fff', bg: '#120B0C' }}>
										View more alternatives from {categoryName[0]}
									</Button>
								</Link>
							</VStack>
							<SimpleGrid
								columns={{
									base: 1,
									sm: 1,
									xl: 2,
									'2xl': filteredSuggestedProducts.length === 1 ? 1 : 2,
								}}
								gap='8px'>
								{filteredSuggestedProducts.map((item: any) => (
									<HorizontalProductCard
										key={item.databaseId}
										props={item}
										outlet={false}
										margin={'.3rem'}
										width={'236px'}
										isProductBundles={false}
									/>
								))}
							</SimpleGrid>
						</HStack>
					</Box>
				</Box>
			) : null}

			{((!loggedIn || isCustomerDefault || isUnlicensedUser) &&
				hasRestrictedProducts) ||
			bundles?.length > 0 ? (
				<></>
			) : (
				<div className={styles.singleproductpage}>
					<div className={styles.productsrelated}>
						<div className={styles.sectiontitle}>
							<div className={styles.divider} />
							<div className={styles.title}>
								<Text
									as='h2'
									className={styles.productsYouMay}>
									Products you may also like
								</Text>
							</div>
						</div>

						<div className={styles.productfeed}>
							<div className={styles.saleproducts}>
								<div className={styles.saleproducts}>
									{[...upsellProducts, ...relatedProducts]
										.filter((item: any) => {
											return !(
												!loggedIn &&
												item.productTags?.nodes?.some(
													(tag: any) => tag.name === 'Pharmaceuticals'
												)
											);
										})
										.slice(0, 5)
										.map((product: any) => (
											<div className={styles.prow1}>
												<RelatedProductsCard
													props={product}
													upsellData={upsellProducts}
													relatedData={relatedProducts}
												/>
											</div>
										))}
								</div>
							</div>
						</div>

						<HStack
							justifyContent='center'
							pt='40px'>
							<Link
								href='/shop'
								_hover={{ textDecoration: 'none', color: '#fff!important' }}>
								<Button
									variant='outline'
									w={{ base: '100%', md: '207px' }}>
									VIEW ALL PRODUCTS
								</Button>
							</Link>
						</HStack>
					</div>
				</div>
			)}
		</Layout>
	);
};

enum WpStockStatusEnum {
	IN_STOCK = 'IN_STOCK',
	OUT_OF_STOCK = 'OUT_OF_STOCK',
	ON_BACKORDER = 'ON_BACKORDER',
}

type ProductProps = PageProps<ProductQuery>;

type ProductQuery = {
	wpSimpleProduct: {
		slug: string;
		name: string;
		databaseId: number;
		shortDescription: string;
		description: string;
		price: string;
		regularPrice: string;
		onSale?: boolean;
		stockStatus: WpStockStatusEnum;
		attributes: {
			attrName: [];
		};
		productSubstitute: {
			substitue: {
				productId: number;
			};
		};

		image?: {
			sourceUrl?: string;
			altText?: string;
		};
		galleryImages: {
			images: [];
		};
		productCategories: {
			catName: [];
		};
		productTags: {
			brandName: [];
		};
		tierPrice: any;
		related: {
			relatedProducts: [];
		};
		upsell: { upsellProducts: [] };
		crossSellProducts: { bundles: [] };
		additionalProductInfo?: { additionalProductInfoRepeater: [] };
		faq: {
			questionsrepeater: [
				{
					question: string;
					answer: string;
				}
			];
		};
	};
	quantity: number;
	key: string;
	onChangeQuantity: (qty: number) => void;
};

type HeadProps = {
	wpSimpleProduct: {
		seo?: {
			title?: string;
			metaDesc?: string;
		};
	};
};

export const query = graphql`
	query PRODUCT($slug: String!) {
		wpSimpleProduct(slug: { eq: $slug }) {
			tierPrice {
				tierPrice {
					price
					price2
					price3
					price4
				}
			}
			seo {
				title
				metaDesc
				metaKeywords
				schema {
					articleType
					pageType
					raw
				}
				breadcrumbs {
					text
					url
				}
			}

			crossSellProducts {
				bundles {
					bundleName
					bundleProducts {
						productSku
					}
				}
			}

			additionalProductInfo {
				additionalProductInfoRepeater {
					name
					description
				}
			}

			related {
				relatedProducts: nodes {
					name
					slug
					databaseId
					image {
						sourceUrl
						altText
					}
					promotions {
						onPromotion
						additionalTag
					}
					productTags {
						nodes {
							name
						}
					}
					... on WpSimpleProduct {
						id
						name
						price
						regularPrice
						onSale
						seo {
							title
							metaDesc
						}
						productTags {
							nodes {
								name
							}
						}
					}
					upsell {
						upsellProducts: nodes {
							name
							slug
							databaseId
							description
							image {
								sourceUrl
								altText
							}
							productTags {
								nodes {
									name
								}
							}
							... on WpSimpleProduct {
								id
								name
								price
								regularPrice
								onSale
								stockStatus
							}
						}
					}
				}
			}
			upsell {
				upsellProducts: nodes {
					name
					slug
					databaseId
					description
					image {
						sourceUrl
						altText
					}
					productTags {
						nodes {
							name
						}
					}
					... on WpSimpleProduct {
						id
						name
						price
						regularPrice
						onSale
						stockStatus
					}
				}
			}

			galleryImages {
				images: nodes {
					sourceUrl
					altText
				}
			}

			name
			slug
			databaseId
			sku
			shortDescription
			description
			stockStatus

			productSubstitute {
				substitute {
					productId
				}
			}

			image {
				sourceUrl
				altText
			}
			promotions {
				onPromotion
				additionalTag
			}
			productCategories {
				catName: nodes {
					name
					slug
				}
			}
			productTags {
				brandName: nodes {
					name
					slug
				}
			}
			attributes {
				attrName: nodes {
					name
					options
				}
			}
			tierPrice {
				tierPrice {
					price
					price2
					price3
					price4
				}
			}
			... on WpSimpleProduct {
				id
				name
				price
				regularPrice
				onSale
				salePrice
			}
			faq {
				questionsrepeater {
					question
					answer
				}
			}
		}
	}
`;

export default Product;

export const Head: React.FC<HeadProps> = ({ data }: any) => {
	const wpSimpleProduct: {
		seo: { title: any; metaDesc: any };
	} = data;
	return (
		<SEO
			title={data.wpSimpleProduct.seo?.title}
			description={data.wpSimpleProduct.seo?.metaDesc}
		/>
	);
};
